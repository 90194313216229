<template>
  <div>
    <Toolbar
      :handle-reset="resetForm"
      :handle-submit="onCreateAddon"
    />
    <AddonForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      refForm="updateForm"
      :loading="isLoading"
    />
    <v-card
      max-width="800px"
      class="mt-4 mx-auto"
    >
      <v-data-table
        :headers="headers"
        :items="subAddons"
        :items-per-page="15"
        :loading="isLoading"
        :loading-text="$t('Loading...')"
        :server-items-length="item.children.length"
        class="elevation-1"
        item-key="@id"
        v-if="item.children && item.children.length"
      >
        <ActionCell
          :handle-delete="() => deleteHandler(props.item)"
          :handle-edit="() => editHandler(props.item)"
          slot="item.action"
          slot-scope="props"
        ></ActionCell>
      </v-data-table>
    </v-card>

    <Loading :visible="isLoading" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';
  import AddonForm from '../../components/addon/Form.vue';
  import Loading from '../../components/Loading';
  import Toolbar from '../../components/Toolbar';
  import CreateMixin from '../../mixins/CreateMixin';
  import UpdateMixin from '../../mixins/UpdateMixin';
  import ActionCell from '../../components/ActionCell';
  import AddonMixin from '@/mixins/AddonMixin';

  import { mapGetters } from 'vuex';

  const servicePrefix = 'Addon';

  const { mapFields } = createHelpers({
    getterType: 'addon/getField',
    mutationType: 'addon/updateField'
  });

  export default {
    name: 'AddonUpdate',
    servicePrefix,
    mixins: [CreateMixin, UpdateMixin, AddonMixin],
    components: {
      Loading,
      Toolbar,
      AddonForm,
      ActionCell
    },
    data() {
      return {
        item: {},
        headers: [
          { text: this.$i18n.t('title'), value: 'title' },
          { text: this.$i18n.t('price'), value: 'price' },
          { text: this.$i18n.t('sortOrder'), value: 'sortOrder' },
          {
            text: this.$i18n.t('Actions'),
            value: 'action',
            sortable: false
          }
        ],
      };
    },
    computed: {
      ...mapFields(['error', 'isLoading', 'created', 'deleted', 'violations', 'handled', 'handleAction']),
      ...mapFields('addon', {
      }),
      ...mapGetters('addon', ['find']),
      subAddons() {
        return this.item.children.map((subAddon) => this.resolveAddon(subAddon)).filter((e) => e);
      },
    },
    methods: {
      ...mapActions('addon', {
        create: 'create',
        retrieve: 'load',
        update: "update",
        actionAddon: 'action',
        deleteItem: 'del',
      }),
      onCreateAddon() {
        if (this.$route.params.parent) {
          this.$refs.createForm.item.parent = this.$route.params.parent;
        }
        this.onSendForm();
      },
      editHandler(item) {
        this.retrieve(item['@id']).then(() => {
          this.$router.push({
            name: `${this.$options.servicePrefix}Update`,
            params: { id: item['@id'] }
          });
        });
      },
      deleteHandler(item) {
        this.deleteItem(item);
      },
      deleteWatcher(deleted) {
        if (!deleted) {
          return;
        }
        this.showMessage(this.deleteMessage);
        this.retrieve(deleted.parent);
      }
    },
    created() {
      this.parent = this.$route.params.parent ? this.find(this.$route.params.parent) : {};
    },

    beforeRouteUpdate(to, from, next) {
      if (to.params.id !== from.params.id) {
        this.loadObjectToUpdate().then(() => this.doResetForm());
      }
      next();
    }
  };
</script>
